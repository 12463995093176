import { useMemo } from 'react';
import { getWallet } from '../requests.mjs';
import useResult from '../response';

export default function useGetWallet(auth, clientId, walletId, siteId = null) {
  const request = useMemo(
    function () {
      return getWallet(auth, clientId, walletId, siteId);
    }, []);
  return useResult(request);
}
