import { useMemo } from 'react';
import { getSites } from '../requests.mjs';
import useResult from '../response';

export default function useGetSites(auth, params = null) {
  const request = useMemo(
    function () {
      return getSites(auth, params);
    },
    [params]
  );
  return useResult(request);
}
