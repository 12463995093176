import { useState, useEffect, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import SiteCard from '../../components/site/card';
import SiteEdit from '../../components/site/edit';
import request, {
  createSite,
  updateSite,
  disableSite,
  enableSite,
} from '../../apis/thrivePerformance/v1/requests.mjs';
import useGetSites from '../../apis/thrivePerformance/v1/sites/getSites';

export default function Clients() {
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [siteEditDialogOpen, setSiteEditDialogOpen] = useState(false);
  const [siteCreateDialogOpen, setSiteCreateDialogOpen] = useState(false);
  const [site, setSite] = useState({});
  const [processing, setProcessing] = useState(false);
  const [params, setParams] = useState({});
  const [sites, error, refreshSites] = useGetSites(auth, params);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  function handleSiteCreate() {
    setSite({});
    setSiteCreateDialogOpen(true);
  }

  function handleSiteEdit(site) {
    setSite(site);
    setSiteEditDialogOpen(true);
  }

  function handleDialogClose() {
    setSiteCreateDialogOpen(false);
    setSiteEditDialogOpen(false);
  }

  async function handleCreateSite(site) {
    setProcessing(true);
    try {
      await request(createSite(auth, site));
      handleDialogClose();
      refreshSites();
      setSnackbar('success', 'The site was created successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the site.');
    }
    setProcessing(false);
  }

  async function handleUpdateSite(site) {
    setProcessing(true);
    try {
      await request(updateSite(auth, site));
      handleDialogClose();
      refreshSites();
      setSnackbar('success', 'The site information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the site information.');
    }
    setProcessing(false);
  }

  async function handleEnableSite(siteId) {
    setProcessing(true);
    try {
      await request(enableSite(auth, siteId));
      refreshSites();
      setSnackbar('success', 'The site was enabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to enable the site.');
    }
    setProcessing(false);
  }

  async function handleDisableSite(siteId) {
    setProcessing(true);
    try {
      await request(disableSite(auth, siteId));
      refreshSites();
      setSnackbar('success', 'The site was disabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to disable the site.');
    }
    setProcessing(false);
  }

  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      setLoading(true);
      try {
        setParams({
          searchTerm: searchValue,
        });
        refreshSites();
      } catch (err) {
        console.log(err);
        setSnackbar('error', 'An error occurred while attempting to retrieve the sites.');
      }
      setLoading(false);
    }, 1000),
    []
  );

  useEffect(() => {
    if (error) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', error.error);
    }
  }, [error]);

  const handlePageChange = (event, value) => {
    setParams({
      ...params,
      ...{ startFrom: (value - 1) * sites.limit },
    });
    setPage(value);
  };

  const totalPages =
    sites && sites.totalSites
      ? Math.floor((sites.totalSites - 1) / sites.limit) + 1
      : 1;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
          Sites
        </Typography>
        <IconButton size="large" color="primary" onClick={handleSiteCreate}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <TextField
          id="filled-search"
          label="Search Sites"
          type="search"
          variant="filled"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            debouncedSearch(event.target.value);
          }}
        />
        <IconButton size="large" color="primary">
          <SearchIcon />
        </IconButton>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {sites &&
          sites.sites &&
          sites.sites.map((site) => {
            return (
              <Grid xs={12} sm={6} lg={4} key={site.siteId}>
                <SiteCard
                  site={site}
                  processing={processing}
                  handleEdit={handleSiteEdit}
                  handleEnable={handleEnableSite}
                  handleDisable={handleDisableSite}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                />
              </Grid>
            );
          })}
        {!sites && loading ? (
          <Grid>
            <CircularProgress color="primary" />
          </Grid>
        ) : null}
      </Grid>
      {totalPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={siteEditDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Site Information</DialogTitle>
        <DialogContent>
          <SiteEdit
            site={site}
            processing={processing}
            handleUpdate={handleUpdateSite}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={siteCreateDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>New Site</DialogTitle>
        <DialogContent>
          <SiteEdit
            site={site}
            processing={processing}
            handleUpdate={handleCreateSite}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
