import { Route } from 'react-router-dom';
import ProtectedRoute from './protected';
import { RequestProvider } from '../apis/thrivePerformance/v1/requestContainer';
import Login from '../views/user/Login';
import ForgotPassword from '../views/user/ForgotPassword';
import Profile from '../views/user/Profile';
import Users from '../views/user/Users';

export default function routes() {
  return (
    <>
      <Route path="login" element={<Login />} />
      <Route path="forgotPassword" element={<ForgotPassword />} />
      <Route
        path="profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="users"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Users />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
    </>
  );
}
