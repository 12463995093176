import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { toTitleCase } from '../../../common/utils.mjs';

export default function featureCard({ feature, meanValue, meanValueChange, sx }) {
  let featureType = '';
  let valueType = '';
  let valueUnits = '';
  switch (feature?.type) {
    case 'eeg':
      featureType = 'EEG';
      valueType = 'Amplitude';
      valueUnits = 'μV';
      break;
    case 'respiration':
      featureType = 'Respiration';
      valueType = 'Respiration';
      valueUnits = 'breaths/minute';
      break;
    case 'skin conductance':
      featureType = 'Skin Conductance';
      valueType = 'Conductivity';
      valueUnits = 'μS';
      break;
    case 'temperature':
      featureType = 'Temperature';
      valueType = 'Temperature';
      valueUnits = '°C';
      break;
    default:
      featureType = toTitleCase(feature.type);
      valueType = 'Amplitude';
      valueUnits = '';
  }

  return (
    <Card sx={sx}>
      <CardContent>
        <Typography variant="h6" color="primary">{feature.name}</Typography>
        <Typography variant="subtitle1">
          {`Type: ${featureType}`}
        </Typography>
        { feature.type !== 'respiration' && (
          <>
            <Typography variant="subtitle1">
              {`Reward or Inhibit: ${feature.reward === true ? 'Reward' : 'Inhibit'}`}
            </Typography>
          </>
        )}
        { feature.type === 'eeg' && (
          <>
            <Typography variant="subtitle1">
              {`Frequency Range: ${feature.bandMin}Hz to ${feature.bandMax}Hz`}
            </Typography>
          </>
        )}
        { feature.type !== 'respiration' && (
          <>
            <Typography variant="subtitle1">
              {`Channels: ${feature.channels.join(', ')}`}
            </Typography>
          </>
        )}
        <Typography variant="subtitle1">
          {`Average Change in ${valueType}: ${meanValueChange !== null ? meanValueChange : 'Undefined '}%`}
        </Typography>
        <Typography variant="subtitle1">
          {`Average ${valueType}: ${meanValue} ${valueUnits}`}
        </Typography>
      </CardContent>
    </Card>
  );
}
