import { Route } from 'react-router-dom';
import ProtectedRoute from './protected';
import { RequestProvider } from '../apis/thrivePerformance/v1/requestContainer';
import PurchasableItems from '../views/payments/PurchasableItems';
import ClientWallet from '../views/payments/Wallet';

export default function routes() {
  return (
    <>
      <Route
        path="purchasableItems"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <PurchasableItems />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="site/:siteId/client/:clientId/wallet/:walletId"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <ClientWallet />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="client/:clientId/wallet/:walletId"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <ClientWallet />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
    </>
  );
}
