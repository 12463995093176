import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import ClientEdit from './edit';
import UserSelect from '../user/select';

export default function createClient({ client, trainers, processing, handleCreate, handleCancel }) {
  const [trainerId, setTrainerId] = useState(client.trainerId);
  const [siteId, setSiteId] = useState(client.siteId);

  function validate(client) {
    handleCreate({
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      siteId: siteId,
      trainerId: trainerId,
    });
  }

  function resetForm() {
    setTrainerId(client.trainerId);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <UserSelect
            users={trainers}
            selectedUserId={trainerId || ''}
            setSelectedUserId={setTrainerId}
          />
        </FormControl>
      </div>
      <ClientEdit
        client={client}
        processing={processing}
        handleUpdate={validate}
        handleCancel={resetForm}
      />
    </Box>
  );
}
