import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function userSelect({users, selectedUserId, setSelectedUserId}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUserId(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="user-selector-label">User</InputLabel>
      <Select
        labelId="user-selector-label"
        id="user-selector"
        value={selectedUserId}
        label="User"
        onChange={handleChange}
      >
        {users.map((user) => (
          <MenuItem key={user.userId} value={user.userId}>
            {user.firstName} {user.lastName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
