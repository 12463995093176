import { useMemo } from 'react';
import { getSite } from '../requests.mjs';
import useResult from '../response';

export default function useGetSite(auth, siteId) {
  const request = useMemo(function () {
    return getSite(auth, siteId);
  }, []);
  return useResult(request);
}
