import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DisableIcon from '@mui/icons-material/DesktopAccessDisabled';
import EnableIcon from '@mui/icons-material/DesktopWindows';
import { toTitleCase } from '../../../common/utils.mjs';

export default function purchasableItemCard({ item, processing, handleEdit, handleActivate, handleDeactivate, locale, timeZone, sx }) {
  function handleEditClick() {
    handleEdit(item);
  }

  function handleActivateClick() {
    handleActivate(item.itemId);
  }

  function handleDeactivateClick() {
    handleDeactivate(item.itemId);
  }

  const createdDate = new Date(item.createdDate);
  const displayDate = createdDate.toLocaleDateString(locale, { timeZone: timeZone });
  const displayTime = createdDate.toLocaleTimeString(locale, { timeZone: timeZone });

  return (
    <Card sx={sx}>
      <CardContent>
        <Typography variant="h6" color="primary">{item.name}</Typography>
        <Typography variant="subtitle1">
          {`Price: ${item.price.toLocaleString(locale, {
            style: 'currency',
            currency: 'USD',
          })}`}
        </Typography>
        <Typography variant="subtitle1">{`Type: ${toTitleCase(item.type)}`}</Typography>
        <Typography variant="subtitle1">{`Description: ${item.description}`}</Typography>
        { item.type === 'time' && (
          <Typography variant="subtitle1">{`Duration: ${item.duration} days`}</Typography>
        )}
        { item.type === 'quantity' && (
          <Typography variant="subtitle1">{`Quantity: ${item.quantity} sessions`}</Typography>
        )}
        <Typography variant="subtitle1">{`Created: ${displayDate} ${displayTime}`}</Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="Edit">
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {item.status === 'active' ? (
          <Tooltip title="Deactivate">
            <IconButton onClick={handleDeactivateClick} disabled={processing || null}>
              <DisableIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Activate">
            <IconButton onClick={handleActivateClick} disabled={processing || null}>
              <EnableIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
}
