export const supportedLocales = [
  'en-AU',
  'en-CA',
  'en-GB',
  'en-US'
];

export const supportedGroups = [
  'User',
  'SiteAdmin',
  'Admin'
];

export const purchasableItemTypes = [
  'time',
  'quantity'
];
