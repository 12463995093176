import { purchasableItemTypes } from '../../common/constants.mjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toTitleCase } from '../../common/utils.mjs';

export default function purchasableItemTypeSelect({ itemType, setItemType }) {
  function handleChange(event) {
    setItemType(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="purchasable-item-type-selector-label">Purchasable Item Type</InputLabel>
      <Select
        labelId="purchasable-item-type-selector-label"
        id="purchasable-item-type-selector"
        value={itemType === null ? '' : itemType}
        label="Purchasable Item Type"
        onChange={handleChange}
      >
        {purchasableItemTypes.map((type) => {
          return (
            <MenuItem key={type} value={type}>
              {toTitleCase(type)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
