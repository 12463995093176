import { useOutletContext, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetClient from '../../apis/thrivePerformance/v1/clients/getClient';
import useGetSession from '../../apis/thrivePerformance/v1/sessions/getSession';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import FeatureCard from '../../components/session/feature/card';

export default function Dashboard() {
  const { siteId, clientId, sessionId } = useParams();
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] =
    useOutletContext();
  const [loading, setLoading] = useState(true);
  const [client, clientError, refreshClient] = useGetClient(auth, clientId, siteId);
  const [session, sessionError, refreshSession] = useGetSession(auth, clientId, sessionId, siteId);

  if (clientError) {
    setSnackbar('error', clientError.error);
  }

  useEffect(() => {
    if (sessionError) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', sessionError.error);
    }
  }, [sessionError]);

  const createdDate = session && session.createdDate ? new Date(session.createdDate) : null;
  const displayDate = createdDate
    ? createdDate.toLocaleDateString(user.locale, { timeZone: user.zoneinfo })
    : '';
  const displayTime = createdDate
    ? createdDate.toLocaleTimeString(user.locale, { timeZone: user.zoneinfo })
    : '';

  return (
    <Box width="100%" minWidth={800}>
      <Typography variant="h3" component="h3">
        {`Client: ${(client && client.firstName) || ''} ${(client && client.lastName) || ''}`}
      </Typography>
      <Typography variant="h5" component="h5" style={{ marginTop: '0.5rem' }}>
        Session:
        { session && session.name ?
          ` ${session.name}` :
          ` ${(displayDate && displayDate) || ''} ${
          (displayTime && displayTime) || ''
          }`
        }
      </Typography>
      <Typography variant="h6" component="h6">
        {`Status: ${session && session.metrics.duration ? 'Completed' : 'Started'}`}
      </Typography>
      {session && session.metrics && session.metrics.duration && (
        <>
          <Typography variant="h6" component="h6">
            {`Duration: ${Math.round(((session.metrics.duration / 1000 / 60) + Number.EPSILON) * 100) / 100} minutes`}
          </Typography>
          <Typography variant="h5" component="h5" sx={{ mt: 2 }}>Features</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {session.metrics.featureMetrics.map((featureMetric, index) => {
              return (
                <Grid xs={6} md={4} lg={4} key={index}>
                  <FeatureCard
                    feature={featureMetric.feature}
                    meanValue={featureMetric.meanValue}
                    meanValueChange={featureMetric.meanValueChange}
                  />
                </Grid>  
              );
            })}
          </Grid>
        </>
      )}
      {!session && loading && <CircularProgress color="primary" />}
    </Box>
  );
}
