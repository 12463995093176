import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/ReadMore';
import DisableIcon from '@mui/icons-material/DesktopAccessDisabled';
import EnableIcon from '@mui/icons-material/DesktopWindows';
import EnabledIcon from '@mui/icons-material/CheckCircle';
import DisabledIcon from '@mui/icons-material/Cancel';

export default function siteCard({ site, processing, handleEdit, handleDisable, handleEnable, locale, timeZone, sx }) {
  function handleEditClick() {
    handleEdit(site);
  }

  function handleDisableClick() {
    handleDisable(site.siteId);
  }

  function handleEnableClick() {
    handleEnable(site.siteId);
  }

  const createdDate = new Date(site.createdDate);
  const displayDate = createdDate.toLocaleDateString(locale, { timeZone: timeZone });
  const displayTime = createdDate.toLocaleTimeString(locale, { timeZone: timeZone });

  return (
    <Card sx={sx}>
      <CardContent>
        <RouterLink to={`/site/${site.siteId}`}>
          <Typography variant="h6" color="primary">
            {site.name}
          </Typography>
        </RouterLink>
        <Typography variant="subtitle1">
          <Link href={`mailto:${site.email}`}>{site.email}</Link>
        </Typography>
        <Typography variant="subtitle1">{`Created: ${displayDate} ${displayTime}`}</Typography>
        <Typography variant="subtitle1">{`Phone: ${site.phone}`}</Typography>
        <Typography variant="subtitle1">{`Address: ${site.address}`}</Typography>
        <Typography variant="subtitle1">
          Enabled:
          {site.status === 'active' ? (
            <EnabledIcon color="primary" fontSize="small" />
          ) : (
            <DisabledIcon color="error" fontSize="small" />
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <RouterLink to={`/site/${site.siteId}`}>
          <Tooltip title="View">
            <IconButton>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </RouterLink>
        <Tooltip title="Edit">
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {site.status === 'active' ? (
          <Tooltip title="Disable">
            <IconButton onClick={handleDisableClick} disabled={processing || null}>
              <DisableIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Enable">
            <IconButton onClick={handleEnableClick} disabled={processing || null}>
              <EnableIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
}
