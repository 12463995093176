import { Route } from 'react-router-dom';
import ProtectedRoute from './protected';
import { RequestProvider } from '../apis/thrivePerformance/v1/requestContainer';
import Sites from '../views/sites/Sites';
import Dashboard from '../views/sites/Dashboard';

export default function routes() {
  return (
    <>
      <Route
        path="sites"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Sites />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="site/:siteId"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Dashboard />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
    </>
  );
}
