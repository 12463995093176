import { Route } from 'react-router-dom';
import ProtectedRoute from './protected';
import { RequestProvider } from '../apis/thrivePerformance/v1/requestContainer';
import Session from '../views/sessions/Session';

export default function routes() {
  return (
    <>
      <Route
        path="client/:clientId/session/:sessionId"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Session />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="site/:siteId/client/:clientId/session/:sessionId"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Session />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
    </>
  );
}
