import { useState } from 'react';
import useGetTags from "../../apis/thrivePerformance/v1/tags/getTags";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import LocaleSelect from '../common/localeSelect';
import TimeZoneSelect from '../common/timeZoneSelect';
import UserGroupSelect from "../common/userGroupSelect";
import SiteSelect from "../site/select";
import InputTags from "../common/inputTags";
import validator from '../../common/validations.mjs';
import request, { createTag } from "../../apis/thrivePerformance/v1/requests.mjs";

export default function editUser({ user, processing, handleUpdate, handleCancel, auth, setSnackbar, sites }) {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [siteId, setSiteId] = useState(user.siteId);
  const [groups, setGroups] = useState(getUserGroupNames(user.groups));
  const [tags, setTags] = useState(user.tags || []);
  const [tagParams, setTagParams] = useState({});
  const [tagOptions, tagOptionsError, refreshTagOptions] = useGetTags(auth, tagParams);
  const [locale, setLocale] = useState(user.locale);
  const [timeZone, setTimeZone] = useState(user.timeZone);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [groupsError, setGroupsError] = useState(false);
  const [siteError, setSiteError] = useState(false);

  function getUserGroupNames(groups) {
    return groups.map((group) => {
      return group.name;
    });
  }

  function getTagValues(tags) {
    return tags.map((tag) => {
      return tag.value;
    });
  }

  async function handleNewTag(value) {
    try {
      if (!tagOptions.includes(value)) {
        await request(createTag(auth, { value: value }));
      }
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to add the tag.');
    }
    refreshTagOptions();
  }

  function validate() {
    let valid = true;
    if (!validator().isName(firstName)) {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }
    if (!validator().isName(lastName)) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }
    if (groups.length === 0) {
      setGroupsError(true);
      valid = false;
    } else {
      setGroupsError(false);
    }
    if (siteId === '') {
      setSiteError(true);
      valid = false;
    } else {
      setSiteError(false);
    }
    if (valid) {
      handleUpdate({
        userId: user.userId,
        firstName: firstName,
        lastName: lastName,
        siteId: siteId,
        groups: groups,
        tags: tags,
        locale: locale,
        timeZone: timeZone,
      });
    }
  }

  function resetForm() {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setSiteId(user.siteId);
    setGroups(getUserGroupNames(user.groups));
    setTags(user.tags || []);
    setLocale(user.locale);
    setTimeZone(user.timeZone);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={firstNameError}>
          <InputLabel htmlFor="outlined-first-name">First Name</InputLabel>
          <OutlinedInput
            id="outlined-first-name"
            type="text"
            value={firstName}
            label="First Name"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
          <FormHelperText>{firstNameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={lastNameError}>
          <InputLabel htmlFor="outlined-last-name">Last Name</InputLabel>
          <OutlinedInput
            id="outlined-last-name"
            type="text"
            value={lastName}
            label="Last Name"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
          <FormHelperText>{lastNameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      {sites && sites.length > 0 && (
        <div>
          <FormControl
            fullWidth
            margin="normal"
            variant="outlined"
          >
            <SiteSelect
              sites={sites}
              selectedSiteId={siteId}
              setSelectedSiteId={setSiteId}
              siteError={siteError}
            />
          </FormControl>
        </div>
      )}
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <UserGroupSelect
            groups={groups}
            setGroups={setGroups}
            groupsError={groupsError}
          />
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <InputTags
            tags={tags}
            setTags={setTags}
            options={tagOptions ? getTagValues(tagOptions) : []}
            handleNewTag={handleNewTag}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <LocaleSelect locale={locale} setLocale={setLocale} />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <TimeZoneSelect timeZone={timeZone} setTimeZone={setTimeZone} />
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button disabled={processing || null} variant="outlined" color="error" onClick={resetForm}>
          Cancel
        </Button>
        <Button disabled={processing || null} sx={{ ml: 1 }} variant="contained" onClick={validate}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
