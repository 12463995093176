import { useMemo } from 'react';
import { getSessions } from '../requests.mjs';
import useResult from '../response';

export default function useGetSessions(auth, clientId, params = null, siteId = null) {
  const request = useMemo(
    function () {
      return getSessions(auth, clientId, params, siteId);
    },
    [params]
  );
  return useResult(request);
}
