import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function InputTags({tags, setTags, options, handleNewTag}) {
  function handleChange(event, value) {
    value.map((tag) => {
      if (!options.includes(tag)) {
        handleNewTag(tag);
      }
    });
    setTags(value);
  }

  return (
    <Autocomplete
      multiple
      id="input-tags"
      options={options}
      defaultValue={[...tags]}
      value={tags}
      freeSolo
      autoSelect
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tags"
          placeholder="Tags"
          value={tags}
        />
      )}
    />
  );
};
