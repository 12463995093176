import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import UserSelect from '../user/select';

export default function reassignClient({ client, trainers, processing, handleReassign, handleCancel }) {
  const [trainerId, setTrainerId] = useState(client.trainerId);

  function handleUpdateClick() {
    handleReassign(trainerId);
  }

  function resetForm() {
    setTrainerId(client.trainerId);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <UserSelect
            users={trainers}
            selectedUserId={trainerId || ''}
            setSelectedUserId={setTrainerId}
          />
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button disabled={processing || null} variant="outlined" color="error" onClick={resetForm}>
          Cancel
        </Button>
        <Button disabled={processing || null} sx={{ ml: 1 }} variant="contained" onClick={handleUpdateClick}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
