import { supportedGroups } from "../../common/constants.mjs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormHelperText from "@mui/material/FormHelperText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function userGroupSelect({groups, setGroups, groupsError}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setGroups(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="user-group-selector-label">User Groups</InputLabel>
      <Select
        labelId="user-group-selector-label"
        id="user-group-selector"
        multiple
        value={groups}
        label="User Groups"
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {supportedGroups.map((group) => (
          <MenuItem key={group} value={group}>
            <Checkbox checked={groups.indexOf(group) > -1} />
            <ListItemText primary={group} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {groupsError && 'You must select at least one group.'}
      </FormHelperText>
    </FormControl>
  );
};
