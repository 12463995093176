import { useMemo } from 'react';
import { getWallets } from '../requests.mjs';
import useResult from '../response';

export default function useGetWallets(auth, clientId, params = null, siteId = null) {
  const request = useMemo(
    function () {
      return getWallets(auth, clientId, params, siteId);
    },
    [params]
  );
  return useResult(request);
}
