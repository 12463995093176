import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ViewIcon from '@mui/icons-material/ReadMore';

export default function sessionCard({ clientId, session, locale, timeZone, sx, siteId }) {
  const sessionDate = new Date(session.createdDate);
  const displayDate = sessionDate.toLocaleDateString(locale, { timeZone: timeZone });
  const displayTime = sessionDate.toLocaleTimeString(locale, { timeZone: timeZone });

  let url = `/client/${clientId}/session/${session.sessionId}`;
  if (siteId) {
    url = `/site/${siteId}${url}`;
  }
  return (
    <Card sx={sx}>
      <CardContent>
        <RouterLink to={url}>
          <Typography variant="h6" color="primary">
            { session.name ?
              session.name :
              `${displayDate || ''} ${displayTime || ''}`
            }
          </Typography>
        </RouterLink>
        <Typography variant="subtitle1">
          {`Status: ${session.metrics.duration ? 'Completed' : 'Started'}`}
        </Typography>
        {session.metrics.duration > 0 && (
          <>
            <Typography variant="subtitle1">
              {`Duration: ${Math.round(((session.metrics.duration / 1000 / 60) + Number.EPSILON) * 100) / 100} minutes`}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions>
        <RouterLink to={url}>
          <Tooltip title="View">
            <IconButton>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </RouterLink>
      </CardActions>
    </Card>
  );
}
