import { useMemo } from 'react';
import { getSession } from '../requests.mjs';
import useResult from '../response';

export default function useGetSession(auth, clientId, sessionId, siteId = null) {
  const request = useMemo(function () {
    return getSession(auth, clientId, sessionId, siteId);
  }, []);
  return useResult(request);
}
