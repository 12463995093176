import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PurchasableItemSelect from '../../purchasableItem/select';

export default function addItem({ items, processing, handleCreate, handleCancel, locale }) {
  const [purchasableItem, setPurchasableItem] = useState('');
  const [startDate, setStartDate] = useState(moment());

  function handleSaveClick() {
    const endDate = moment(startDate).add(purchasableItem.duration, 'days');
    handleCreate({
      name: purchasableItem.name,
      description: purchasableItem.description,
      price: purchasableItem.price,
      type: purchasableItem.type,
      quantity: purchasableItem.quantity,
      duration: purchasableItem.duration,
      startDate: startDate.startOf('day').format(),
      endDate: endDate.endOf('day').format(),
    });
  }

  function resetForm() {
    setPurchasableItem('');
    setStartDate(moment());
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <PurchasableItemSelect
            items={items}
            selectedItemId={purchasableItem}
            setSelectedItem={setPurchasableItem}
            locale={locale}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal">
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
          />
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button disabled={processing || null} variant="outlined" color="error" onClick={resetForm}>
          Cancel
        </Button>
        <Button disabled={processing || null} sx={{ ml: 1 }} variant="contained" onClick={handleSaveClick}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
