import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function purchasableItemSelect({ items, selectedItemId, setSelectedItem, locale }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedItem(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="purchasable-item-selector-label">Purchasable Item</InputLabel>
      <Select
        labelId="purchasable-item-selector-label"
        id="purchasable-item-selector"
        value={selectedItemId}
        label="Pruchasable Item"
        onChange={handleChange}
      >
        {items.map((item) => (
          <MenuItem key={item.itemId} value={item}>
            {item.name} - {item.price.toLocaleString(locale, {
              style: "currency",
              currency: 'USD',
            })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
