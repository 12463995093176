import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import validator from '../../common/validations.mjs';

export default function editSite({ site, processing, handleUpdate, handleCancel }) {
  const [name, setName] = useState(site.name);
  const [email, setEmail] = useState(site.email);
  const [phone, setPhone] = useState(site.phone);
  const [address, setAddress] = useState(site.address);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);

  function validate() {
    let valid = true;
    if (!validator().isName(name)) {
      setNameError(true);
      valid = false;
    } else {
      setNameError(false);
    }
    if (!validator().isEmail(email)) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }
    if (!validator().isDescription(phone)) {
      setPhoneError(true);
      valid = false;
    } else {
      setPhoneError(false);
    }
    if (!validator().isDescription(address)) {
      setAddressError(true);
      valid = false;
    } else {
      setAddressError(false);
    }
    if (valid) {
      handleUpdate({
        siteId: site.siteId,
        name: name,
        email: email,
        phone: phone,
        address: address,
      });
    }
  }

  function resetForm() {
    setName(site.name);
    setEmail(site.email);
    setPhone(site.phone);
    setAddress(site.address);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={nameError}>
          <InputLabel htmlFor="outlined-name">Name</InputLabel>
          <OutlinedInput
            id="outlined-first-name"
            type="text"
            value={name}
            label="Name"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <FormHelperText>{nameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={emailError}>
          <InputLabel htmlFor="outlined-email">Email</InputLabel>
          <OutlinedInput
            id="outlined-email"
            type="text"
            value={email}
            label="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <FormHelperText>{emailError && 'This email is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={phoneError}>
          <InputLabel htmlFor="outlined-phone">Phone</InputLabel>
          <OutlinedInput
            id="outlined-phone"
            type="text"
            value={phone}
            label="Phone"
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
          <FormHelperText>{emailError && 'This phone number is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={addressError}>
          <InputLabel htmlFor="outlined-address">Address</InputLabel>
          <OutlinedInput
            id="outlined-address"
            type="text"
            value={address}
            label="Address"
            onChange={(event) => {
              setAddress(event.target.value);
            }}
          />
          <FormHelperText>{emailError && 'This address is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button disabled={processing || null} variant="outlined" color="error" onClick={resetForm}>
          Cancel
        </Button>
        <Button disabled={processing || null} sx={{ ml: 1 }} variant="contained" onClick={validate}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
