import { Route } from 'react-router-dom';
import ProtectedRoute from './protected';
import { RequestProvider } from '../apis/thrivePerformance/v1/requestContainer';
import Clients from '../views/clients/Clients';
import Dashboard from '../views/clients/Dashboard';

export default function routes() {
  return (
    <>
      <Route
        path="clients"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Clients />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="client/:clientId"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Dashboard />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="site/:siteId/clients"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Clients />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="site/:siteId/client/:clientId"
        element={
          <ProtectedRoute>
            <RequestProvider>
              <Dashboard />
            </RequestProvider>
          </ProtectedRoute>
        }
      />
    </>
  );
}
