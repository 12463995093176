import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ViewIcon from '@mui/icons-material/ReadMore';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EnabledIcon from '@mui/icons-material/CheckCircle';
import DisabledIcon from '@mui/icons-material/Cancel';

export default function walletCard({ clientId, wallet, handleAddItem, sx, siteId = null }) {
  function handleAddItemClick() {
    handleAddItem(wallet.walletId);
  }

  const today = new Date();
  let availableSessionQuantity = 0;
  let activeSubscription = false;
  wallet.items.map((item) => {
    if (item.type === 'quantity') {
      availableSessionQuantity += item.quantity;
    }
    if (item.type === 'time') {
      if (new Date(item.startDate) <= today && new Date(item.endDate) >= today) {
        activeSubscription = true;
      }
    }
  });

  let url = `/client/${clientId}/wallet/${wallet.walletId}`;
  if (siteId) {
    url = `/site/${siteId}${url}`;
  }

  return (
    <Card sx={sx}>
      <CardContent>
        <Typography variant="subtitle1">{`Available Sessions: ${availableSessionQuantity}`}</Typography>
        <Typography variant="subtitle1">
          Active Subscription:
          {activeSubscription ? (
            <EnabledIcon color="primary" fontSize="small" />
          ) : (
            <DisabledIcon color="error" fontSize="small" />
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <RouterLink to={url}>
          <Tooltip title="View">
            <IconButton>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </RouterLink>
        <Tooltip title="Add Item">
          <IconButton onClick={handleAddItemClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
