import { useMemo } from 'react';
import { getTrainers } from '../requests.mjs';
import useResult from '../response';

export default function useGetTrainers(auth, params = null) {
  const request = useMemo(
    function () {
      return getTrainers(auth, params);
    },
    [params]
  );
  return useResult(request);
}
