import { useState, useEffect, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import PruchasableItemCard from '../../components/payments/purchasableItem/card';
import PruchasableItemEdit from '../../components/payments/purchasableItem/edit';
import request, {
  createPurchasableItem,
  updatePurchasableItem,
  deactivatePurchasableItem,
  activatePurchasableItem,
} from '../../apis/thrivePerformance/v1/requests.mjs';
import useGetPurchasableItems from '../../apis/thrivePerformance/v1/payments/getPurchasableItems';

export default function Clients() {
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [purchasableItemEditDialogOpen, setPurchasableItemEditDialogOpen] = useState(false);
  const [purchasableItemCreateDialogOpen, setPurchasableItemCreateDialogOpen] = useState(false);
  const [purchasableItem, setPurchasableItem] = useState({});
  const [processing, setProcessing] = useState(false);
  const [params, setParams] = useState({});
  const [purchasableItems, error, refreshPurchasableItems] = useGetPurchasableItems(auth, params);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  function handlePurchasableItemCreate() {
    setPurchasableItem({});
    setPurchasableItemCreateDialogOpen(true);
  }

  function handlePurchasableItemEdit(item) {
    setPurchasableItem(item);
    setPurchasableItemEditDialogOpen(true);
  }

  function handleDialogClose() {
    setPurchasableItemCreateDialogOpen(false);
    setPurchasableItemEditDialogOpen(false);
  }

  async function handleCreatePurchasableItem(item) {
    setProcessing(true);
    try {
      await request(createPurchasableItem(auth, item));
      handleDialogClose();
      refreshPurchasableItems();
      setSnackbar('success', 'The puchasable item was created successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the purchasable item.');
    }
    setProcessing(false);
  }

  async function handleUpdatePurchasableItem(item) {
    setProcessing(true);
    try {
      await request(updatePurchasableItem(auth, item));
      handleDialogClose();
      refreshPurchasableItems();
      setSnackbar('success', 'The purchasable item information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the purchasable item information.');
    }
    setProcessing(false);
  }

  async function handleActivatePurchasableItem(itemId) {
    setProcessing(true);
    try {
      await request(activatePurchasableItem(auth, itemId));
      refreshPurchasableItems();
      setSnackbar('success', 'The purchasable item was activated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to activate the purchasable item.');
    }
    setProcessing(false);
  }

  async function handleDeactviatePurchasableItem(itemId) {
    setProcessing(true);
    try {
      await request(deactivatePurchasableItem(auth, itemId));
      refreshPurchasableItems();
      setSnackbar('success', 'The purchasable item was deactivated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to deactivate the purchasable item.');
    }
    setProcessing(false);
  }

  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      setLoading(true);
      try {
        setParams({
          searchTerm: searchValue,
        });
        refreshPurchasableItems();
      } catch (err) {
        console.log(err);
        setSnackbar('error', 'An error occurred while attempting to retrieve the purchasable items.');
      }
      setLoading(false);
    }, 1000),
    []
  );

  useEffect(() => {
    if (error) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', error.error);
    }
  }, [error]);

  const handlePageChange = (event, value) => {
    setParams({
      ...params,
      ...{ startFrom: (value - 1) * purchasableItems.limit },
    });
    setPage(value);
  };

  const totalPages =
    purchasableItems && purchasableItems.totalItems
      ? Math.floor((purchasableItems.totalItems - 1) / purchasableItems.limit) + 1
      : 1;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
          Purchasable Items
        </Typography>
        <IconButton size="large" color="primary" onClick={handlePurchasableItemCreate}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <TextField
          id="filled-search"
          label="Search Puchasable Items"
          type="search"
          variant="filled"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            debouncedSearch(event.target.value);
          }}
        />
        <IconButton size="large" color="primary">
          <SearchIcon />
        </IconButton>
      </Stack>
      <Grid container gap={2} spacing={2} sx={{ mt: 1 }}>
        {purchasableItems &&
          purchasableItems.items &&
          purchasableItems.items.map((item) => {
            return (
              <Grid xs={12} sm={4} lg={3} key={item.itemId}>
                <PruchasableItemCard
                  item={item}
                  processing={processing}
                  handleEdit={handlePurchasableItemEdit}
                  handleActivate={handleActivatePurchasableItem}
                  handleDeactivate={handleDeactviatePurchasableItem}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                />
              </Grid>
            );
          })}
        {!purchasableItems && loading ? (
          <Grid>
            <CircularProgress color="primary" />
          </Grid>
        ) : null}
      </Grid>
      {totalPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={purchasableItemEditDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Purchasable Item Information</DialogTitle>
        <DialogContent>
          <PruchasableItemEdit
            item={purchasableItem}
            processing={processing}
            handleUpdate={handleUpdatePurchasableItem}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={purchasableItemCreateDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>New Purchasable Item</DialogTitle>
        <DialogContent>
          <PruchasableItemEdit
            item={purchasableItem}
            processing={processing}
            handleUpdate={handleCreatePurchasableItem}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
