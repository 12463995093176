import { Link as RouterLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import SiteIcon from '@mui/icons-material/PlaceOutlined';
import StoreIcon from '@mui/icons-material/StoreOutlined';
import UserIcon from '@mui/icons-material/ManageAccountsOutlined';

export default function MainMenu({ isLoggedIn, primaryGroup, user }) {
  return (
    <>
      <nav aria-label="main">
        <List>
          <ListItemButton component={RouterLink} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          {isLoggedIn && primaryGroup === 'SiteAdmin' && user && (
            <ListItemButton component={RouterLink} to={`/site/${user.siteId}`}>
              <ListItemIcon>
                <SiteIcon />
              </ListItemIcon>
              <ListItemText primary="Site" />
            </ListItemButton>
          )}
          {isLoggedIn && primaryGroup === 'Admin' && (
            <ListItemButton component={RouterLink} to="/sites">
              <ListItemIcon>
                <SiteIcon />
              </ListItemIcon>
              <ListItemText primary="Sites" />
            </ListItemButton>
          )}
          {isLoggedIn && primaryGroup !== 'Admin' && (
            <ListItemButton component={RouterLink} to="/clients">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItemButton>
          )}
          {isLoggedIn && primaryGroup === 'Admin' && (
            <ListItemButton component={RouterLink} to="/purchasableItems">
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Purchasable Items" />
            </ListItemButton>
          )}
          {isLoggedIn && primaryGroup === 'Admin' && (
            <ListItemButton component={RouterLink} to="/users">
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItemButton>
          )}
        </List>
      </nav>
    </>
  );
}
