import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

export default function siteSelect({sites, selectedSiteId, setSelectedSiteId, siteError}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSiteId(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="site-selector-label">Site</InputLabel>
      <Select
        labelId="site-selector-label"
        id="site-selector"
        value={selectedSiteId}
        label="Site"
        onChange={handleChange}
      >
        {sites.map((site) => (
          <MenuItem key={site.siteId} value={site.siteId}>
            {site.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {siteError && 'You must select a site.'}
      </FormHelperText>
    </FormControl>
  );
};
